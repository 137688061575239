.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-size: 10px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', 'Segoe UI', 'Roboto', 'Oxygen',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*zoom: 90%;*/
}

@media only screen and (max-width: 500px) {
  .ant-layout-content {
    padding: 65px 10px 0px 10px !important;
  }  
}

@media only screen and (min-width: 500px) {
  .ant-layout-content {
    padding: 15px 10px 0px 10px !important;
  }  
}

.custom-scroll::-webkit-scrollbar{
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
}
/*.custom-scroll::-webkit-scrollbar-track{
  background-color: red;
}*/
.custom-scroll::-webkit-scrollbar-thumb{
  background-color: #aaaaaa;
}
.custom-scroll::-webkit-scrollbar-track-piece{
  background-color: #e8e8e8;
}
