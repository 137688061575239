.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.form-order .ant-col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.custom-form .ant-form-explain {
  margin-left: 8% !important;
}

.custom-step .ant-steps-item-icon {
  cursor: pointer;
}

.form-order-view .ant-input-disabled {
  color: dimgrey;
}
.form-order-view .ant-input-number-disabled {
  color: dimgrey;
}